
/* sub-visual */
.sub-visual {
    position:relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 180px;
    &__bg{
        position: absolute;
        width: 100%;
        z-index: -1;
        right: 0;
        top: var(--header-body-height);
        background: url(/images/sub/sv-bg.svg) no-repeat center / cover;
    }
    &--tabs{
        height: 230px;
    }
}
::v-deep{
    .sub-tab{
        position: relative;
        width: calc(100% + (var(--container-gutter) * 2));
        left: calc(var(--container-gutter) * -1);
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: var(--v-grey-lighten4);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 240px;
        padding-top:0;
    }
    ::v-deep{
        .sub-tab{
            position: relative;
            width: 100%;
            left: 0;
            &::before{
                display: none;
            }
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 340px;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        &__bg{
            min-width: 1920px;
        }
    }
}
