
.swiper-pagination {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    &--sm {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    width: 8px;
                    height: 8px;
                    margin: 0 4px;
                }
            }
        }
    }
}
::v-deep {
    .swiper-pagination {
        &-bullets {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-bullet {
            position: relative;
            display: block;
            width: 10px;
            height: 10px;
            margin: 0 6px;
            border-radius: 100%;
            opacity: 0.2;
            background-color: var(--v-primary-base);
            border: 1px solid var(--v-primary-base);
            transition: 0.15s ease-out;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &-active {
                opacity: 1;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination {
        bottom: 50px;
    }
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                width: 12px;
                height: 12px;
                margin: 0 8px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
