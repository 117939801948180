
.business-item{
    &__img-inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-out;
        >*{
            opacity: 0;
            visibility: hidden;
            transform: scale(1.2);
            transition: 0.3s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .business-item{
        &--link:hover{
            .business-item__img-inner{
                opacity: 1;
                visibility: visible;
                >*{
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                    transition: 0.5s ease-out;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

