
// 확인을 위한 변경
.lnb {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: auto;
    opacity: 1;
    visibility: visible;
}
