
.lawyer-item{
    display: block;
    position: relative;
    &__contents{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: rgba(13, 25, 50, .6);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
