
.lnb--topline ::v-deep {
    top: calc(var(--header-body-height) - 4px);
    border: 1px solid var(--border-color);
    border-top: 4px solid var(--v-grey-darken4);
    > li {
        > a {
            &:hover {
                color: var(--v-grey-darken4);
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
