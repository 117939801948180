
.story-item{
    position: relative;
    &__inner{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-out;
    }
    &__tit{
        ::v-deep{
            .tit,
            .txt{
                transition: .3s ease-out;
            }            
        }
    }
}
.row{
    >[class*="col"]:nth-child(5n - 4){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 3){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg2.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 2){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg3.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 1){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg4.jpg);
        }
    }
    >[class*="col"]:nth-child(5n){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg5.jpg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-item:hover{
        &::after{
            opacity: 1;
            visibility: visible;
        }        
        .story-item__tit{
            ::v-deep{
                .tit{
                    color: #fff !important;
                }  
                .txt{
                    color: var(--v-grey-lighten3) !important;
                }            
            }
        }
    }
}
@media (min-width:1200px){
}

