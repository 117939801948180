
.app--main{
    .header{
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo.svg);
                }
            }
        }
        ::v-deep{
            .header-body{
                transition: .2s ease-out;
                height: auto;
                padding: 36px 0;
            }
        }
    }
    .header.scroll-active{
        background-color: rgba(0, 0, 0, .6);
        ::v-deep{
            .header-body{
                padding: 20px 0;
            }
        }
    }
}
