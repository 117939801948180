
.sub-image-title{
    &__contents{
        position: relative;
    }
    &__title{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        .tit{
            line-height: .8;
        }
    }
}
