
.intro-contents{
    &--1{
        position: relative;
        &__object{
            font-weight: 600;
            font-size: 6.6rem;
            font-family: var(--font-secondary);
            position: absolute;
            top: 50%;
            right: 0;
            line-height: .8;
            color: var(--v-grey-lighten5);
            text-align: right;
            white-space: nowrap;
        }
    }
    &--4{
        &__object{
            width: 100vw;
            height: calc(var(--page-section-padding-y) * 2);
            background-color: var(--v-grey-lighten5);
        }
        &__foot{
            margin-top: calc((var(--page-section-padding-y) * -1) - (var(--grid-gutter) * 2));
        }
    }
}
@media (min-width:576px){
    .intro-contents{
        &--1{
            &__object{
                font-size: 8rem;
            }
        }
    }
}
@media (min-width:768px){
    .intro-contents{
        &--1{
            &__object{
                top: unset;
                bottom: 0;
                font-size: 12rem;
            }
        }
    }
}
@media (min-width:1024px){
    .intro-contents{
        &--1{
            &__object{
                font-size: 16rem;
            }
        }
    }
}
@media (min-width:1200px){
    .intro-contents{
        &--1{
            position: relative;
            &__object{
                font-size: 24rem !important;                
                bottom: var(--page-section-padding-y);
            }
        }
    }
}
@media (min-width:1440px){
    .intro-contents{
        &--1{
            position: relative;
            &__object{
                bottom: var(--page-section-padding-y);
            }
        }
    }
}

