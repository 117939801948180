
.lawyer-view{
    &__image{
        position: relative;
        &__bg{
            position: absolute;
            z-index: -1;
            &--1{
                width: 100vw;
                height: 50%;
                background-color: var(--v-grey-lighten5);
                right: calc(var(--container-gutter) * -1);
                bottom: 0;

            }
            &--2{
                left: 0;
                top: 0;
                width: 100%;
            }
        }
    }
    &__title{
        width: 100%;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer-view{
        &__image{
            position: relative;
            &__bg{
                &--1{
                    height: 318px;
                    right: 0;
                }
                &--2{
                    top: unset;
                    bottom: 300px;                    
                }
            }
        }
        &__title{
            height: 318px;
        }
    }
}
@media (min-width:1024px){
    .lawyer-view{
        &__title{
            padding-left: 40px;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .lawyer-view{
        &__title{
            padding-left: 110px;
        }
        &__image{
            position: relative;
            &__bg{
                &--2{
                    bottom: 290px;                    
                }
            }
        }
    }
}
